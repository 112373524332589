import IExternalIntegrations, {
  AssetType,
  FractionalizedOrderDetail,
  NftOwners,
  PaginatedQueryParams,
  ProductType,
  UserData
} from "~dApp/models/IExternalIntegrations";
import TraderService from "~dApp/TraderService";
import AltrApiService from "~dApp/AltrApiService";
import {
  GetUserActivity,
  GetNftOwner,
  Get0xTransactionHistory,
  Get0xTransactionsByNftId,
  GetAllTransactionHistory,
  GetBuyoutDataFromFragmentsId,
  GetBuyoutDataFromInitiator,
  GetBuyoutDataFromFragmentsIdAndInitiator,
  GetAllTransactionsByNftIdAndAddress,
  GetNftValuationHistory,
  GetRaffleById,
  GetRaffleBoosts,
  GetFractionsOwners,
  GetFractionsDataFromNft
} from "~dApp/GraphQuery";
import { BrandVM, ConfigVM, GeneralOracleInfoVm, NftFeeManagerDataVM, ProductDetailVMOfBaseProductVM, SubscribeToProductDropVM } from "~dApp/models/ApiModel";
import { ApprovalOverrides, Fee, FillOrderOverrides, OrderStatusV4, SwappableAssetV4 } from "@lucidao-developer/nft-swap-sdk";
import { PostOrderResponsePayload } from "@lucidao-developer/nft-swap-sdk/dist/sdk/v4/orderbook";
import { Buyout, ERC1155, Raffle, Transaction, Transaction0x } from ".graphclient";
import { FractionSaleTransactionVM } from "~dApp/models/ApiModel";
import { LoggedUserVM } from "~dApp/models/ApiModel";
import { NFTDataVM } from "~dApp/models/ApiViewModel";
import { ipfsIoFallback, TARGET_CHAIN_ALCHEMY, walletAddressAreEqual } from "~utils/helpers";
import { Alchemy, Nft, NftTokenType, OwnedNft } from "alchemy-sdk";
import { ApprovalStatus } from "@lucidao-developer/nft-swap-sdk/dist/sdk/common/types";
import { keccak256, PublicClient, toBytes, WalletClient } from "viem";

class ExternalIntegrationsImpl implements IExternalIntegrations {
  trader = (publicClient: PublicClient, walletClient?: WalletClient) => ({
    traderService: new TraderService(publicClient, walletClient),
    buildAsset(tokenAddress: string, type: AssetType, tokenId: string, amount: string): SwappableAssetV4 {
      return this.traderService.buildAsset(tokenAddress, type, tokenId, amount);
    },
    async getTokenDecimals(nftData: NFTDataVM) {
      return this.traderService.getTokenDecimals(nftData);
    },
    async getFees(productIdentifier: string, amount: bigint): Promise<{ makerFee: bigint; takerFee: bigint; feeObject: Fee }> {
      return this.traderService.getFees(productIdentifier, amount);
    },
    async buildAssetFromEnrichedProduct(enrichedProduct: ProductDetailVMOfBaseProductVM, totalPrice: bigint) {
      return this.traderService.buildAssetFromEnrichedProduct(enrichedProduct, totalPrice);
    },
    async approveSwappableAsset(asset: SwappableAssetV4, overrides?: { exchangeProxyAddress?: `0x${string}` }): Promise<string | null> {
      return this.traderService.approveSwappableAsset(asset, overrides);
    },
    async approveTokenOrder(
      order: PostOrderResponsePayload,
      overrides?: { fractionsAmountOverride?: string; exchangeProxyAddress?: `0x${string}` }
    ): Promise<string | null> {
      return this.traderService.approveTokenOrder(order, overrides);
    },
    async createOrder(
      product: ProductDetailVMOfBaseProductVM,
      token: string,
      tokenAmount: bigint,
      type: "buy" | "sell",
      orderType: "ERC721" | "ERC1155",
      fractionsAmount?: string,
      expiry?: string | number | Date,
      taker?: string
    ): Promise<PostOrderResponsePayload> {
      if (type == "buy") {
        return this.traderService.createBuyOrder(product, token, tokenAmount, orderType, fractionsAmount, expiry, taker);
      } else if (type == "sell") {
        return this.traderService.createSellOrder(product, token, tokenAmount, orderType, fractionsAmount, expiry);
      }
    },
    async fillOrderByProduct(product, overrides?: Partial<FillOrderOverrides>, config?: ConfigVM): Promise<string> {
      return this.traderService.fulfillOrderByProduct(product, overrides, config);
    },
    async fillOrder(order: PostOrderResponsePayload, overrides?: Partial<FillOrderOverrides>, config?: ConfigVM): Promise<string> {
      return this.traderService.fulfillOrder(order, overrides, config);
    },
    async cancelOrder(order: PostOrderResponsePayload): Promise<string> {
      return this.traderService.cancelNftOrder(order);
    },
    async get0xTokenAllowance(tokenAddress: string) {
      return this.traderService.get0xTokenAllowance(tokenAddress);
    },
    async getOrderByNonce(nonce: string) {
      return this.traderService.getOrdersByNonce(nonce);
    },
    async loadApprovalStatus(asset: SwappableAssetV4, address: string, approvalOverrides?: ApprovalOverrides): Promise<ApprovalStatus> {
      return this.traderService.loadApprovalStatus(asset, address, approvalOverrides);
    }
  });
  graph = {
    async getUserActivity(address: string): Promise<any> {
      return GetUserActivity({ userAddress: address });
    },
    async getNftOwners(nftData: NFTDataVM, isWhole = true): Promise<NftOwners> {
      let res: NftOwners = { owners: [] };
      if (isWhole) {
        const nftId = `${nftData.nftCollectionInfo.collectionAddress}${nftData.tokenId}`;
        const { erc721 } = await GetNftOwner({ nftId });
        res = { owners: [], erc721Owner: erc721.owner };
      } else {
        const nftId = `${nftData.nftCollectionInfo.fractionSaleAddresses.fractionsContractAddress}${nftData.saleId}`;
        const { erc1155 } = await GetFractionsOwners({ nftId });
        for (let i = 0; i < erc1155.owners.length; i++) {
          if (!walletAddressAreEqual(nftData.nftCollectionInfo.fractionSaleAddresses.fractionSaleContractAddress, erc1155.owners[i].address)) {
            let index = -1;
            for (let j = 0; j < erc1155.owners[i]?.erc1155.length; j++) {
              if (erc1155.id === erc1155.owners[i]?.erc1155[j].id) {
                index = j;
              }
            }
            if (index != -1) {
              res.owners.push({
                address: erc1155.owners[i].address,
                fractions: erc1155.owners[i].erc1155Balance[index]
              });
            }
          }
        }
      }

      return res;
    },
    async getFractionsDataFromNft(nftData: NFTDataVM): Promise<ERC1155> {
      const nftId = `${nftData.nftCollectionInfo.collectionAddress}${nftData.nftCollectionInfo.identifier}`;
      const { erc721 } = await GetFractionsDataFromNft({ nftId });
      return erc721.erc1155 as ERC1155;
    },
    async get0xTransactionsByNftId(contractAddress: string, tokenId: number, orderDirection: `asc` | `desc` = `desc`): Promise<Transaction0x[]> {
      const { transaction0Xes } = await Get0xTransactionsByNftId({ contractAddress, tokenId, orderDirection });
      return transaction0Xes as Transaction0x[];
    },
    async get0xTransactionHistory(userAddress: string, orderDirection?: `asc` | `desc`): Promise<Transaction0x[]> {
      const { transaction0Xes } = await Get0xTransactionHistory({ userAddress, orderDirection });
      return transaction0Xes as Transaction0x[];
    },
    async getAllTransactionHistory(userAddress: string, orderDirection?: `asc` | `desc`): Promise<(Transaction0x | Transaction)[]> {
      const { transaction0Xes, transactions } = await GetAllTransactionHistory({ userAddress, orderDirection });
      return transactions.map((itm) => ({
        ...transaction0Xes.find((item) => item.id === itm.id && item),
        ...itm
      })) as any;
    },
    async getBuyoutDataFromFragmentsId(contractAddress: string, tokenId: string): Promise<Buyout[]> {
      const { buyouts } = await GetBuyoutDataFromFragmentsId({ erc1155Id: `${contractAddress}${tokenId}` });
      return buyouts as Buyout[];
    },
    async getBuyoutDataFromInitiator(userAddress: string): Promise<Buyout[]> {
      const { buyouts } = await GetBuyoutDataFromInitiator({ userAddress });
      return buyouts as Buyout[];
    },
    async getBuyoutDataFromFragmentsIdAndInitiator(userAddress: string, contractAddress: string, tokenId: string): Promise<Buyout[]> {
      const erc1155Id = `${contractAddress}${tokenId}`;
      const { buyouts } = await GetBuyoutDataFromFragmentsIdAndInitiator({ userAddress, erc1155Id });
      return buyouts as Buyout[];
    },
    async getAllTransactionsByNftIdAndAddress(
      userAddress: string,
      contractAddress: string,
      tokenId: string,
      orderDirection?: `asc` | `desc`
    ): Promise<(Transaction | Transaction0x)[]> {
      const { transactions, transaction0Xes } = await GetAllTransactionsByNftIdAndAddress({ userAddress, contractAddress, tokenId, orderDirection });
      return transactions.map((itm) => ({
        ...transaction0Xes.find((item) => item.id === itm.id && item),
        ...itm
      })) as any;
    },
    async GetNftValuationHistory(contractAddress: string, tokenId: string) {
      const { nft } = await GetNftValuationHistory({ nftId: `${contractAddress}${tokenId}` });
      return nft.valuationHistory;
    },
    async getRaffleById(raffleId: string): Promise<Raffle> {
      const { raffle } = await GetRaffleById({ raffleId });
      return raffle;
    },
    async getRaffleBoosts(userAddress: string, referralCode: string, raffleId: string, endEarlyBirdTimestamp: string): Promise<any> {
      userAddress = userAddress.toLowerCase();
      const referralId = `${raffleId}-${keccak256(toBytes(referralCode))}`;
      return GetRaffleBoosts({ userAddress, userAddressId: userAddress, referralId, raffleId, endEarlyBirdTimestamp });
    }
  };
  api = {
    COINGECKO_BASE_URL: process.env.GATSBY_COINGECKO_API,
    altrApiService: new AltrApiService(),
    alchemy: new Alchemy({
      apiKey: process.env.GATSBY_ALCHEMY_API_KEY,
      network: TARGET_CHAIN_ALCHEMY
    }),
    async getOwnersForNfts(nfts: { contractAddress: string; tokenId: string }[]) {
      return Promise.all(nfts.map(({ contractAddress, tokenId }) => this.alchemy.nft.getOwnersForNft(contractAddress, tokenId)));
    },
    async getBaseProductById(id: string) {
      return this.altrApiService.getBaseProductById(id);
    },
    async getProductById(id: string, type: ProductType): Promise<ProductDetailVMOfBaseProductVM> {
      return this.altrApiService.getLucidProductById(id, type);
    },
    async getProductsByIds(ids: { id: string; type: ProductType }[]): Promise<ProductDetailVMOfBaseProductVM[]> {
      return this.altrApiService.getLucidProductsByIds(ids);
    },
    async getOracleById(id: string): Promise<GeneralOracleInfoVm> {
      return this.altrApiService.getOracleById(id);
    },
    async getOracleList(): Promise<GeneralOracleInfoVm[]> {
      return this.altrApiService.getOracleList();
    },
    async getBrandList(): Promise<BrandVM[]> {
      return this.altrApiService.getBrandList();
    },
    async getProductExpertById(id: string): Promise<any> {
      return this.altrApiService.getProductExpertById(id);
    },
    async getPurchaseControlsStatus(product: ProductDetailVMOfBaseProductVM): Promise<string> {
      return this.altrApiService.getPurchaseControlsStatus(product);
    },
    async getProductPurchaseStatus(productIdentifier: string): Promise<string> {
      return this.altrApiService.getProductPurchaseStatus(productIdentifier);
    },
    async enrichSanityProduct(product: ProductDetailVMOfBaseProductVM): Promise<string> {
      return this.altrApiService.enrichSanityProduct(product);
    },
    async getFullProductByProductGUID(guid: string): Promise<ProductDetailVMOfBaseProductVM> {
      return this.altrApiService.getFullProductByProductGUID(guid);
    },
    async updateOrderStatus(orderStatus: OrderStatusV4): Promise<OrderStatusV4> {
      return this.altrApiService.buyNft(orderStatus);
    },
    async getProductsOnSale(): Promise<ProductDetailVMOfBaseProductVM[]> {
      return this.altrApiService.getProductsOnSale();
    },
    async getOrCreateKYCSession(user: LoggedUserVM): Promise<{ sessionId: string; status: string; kycEnabled: boolean }> {
      return this.altrApiService.getOrCreateKYCSession(user);
    },
    getNftPrice(nftData: NFTDataVM): string {
      return this.altrApiService.getNftPrice(nftData);
    },
    async getFractionalizedOrderDetailBySaleGuid(saleGuid: string): Promise<FractionalizedOrderDetail> {
      return this.altrApiService.getFractionalizedOrderDetailsBySaleGuid(saleGuid);
    },
    async acceptPurchaseTermsAndConditions(
      nftIdentifier: string,
      vaultTermsAndConditions: string,
      nftTermsAndConditions: string,
      message: string,
      signedMessage: string,
      nonce: string
    ): Promise<void> {
      await this.altrApiService.acceptPurchaseTermsAndConditions(nftIdentifier, vaultTermsAndConditions, nftTermsAndConditions, message, signedMessage, nonce);
    },
    async getFractionSaleTransaction(txId: string): Promise<FractionSaleTransactionVM> {
      return this.altrApiService.getFractionSaleTransaction(txId);
    },
    async saveFractionSaleTransaction(
      saleGuid: string,
      nftData: NFTDataVM,
      purchaseTxData: string,
      purchasePrice: string,
      purchasedFractions: string
    ): Promise<string> {
      return this.altrApiService.saveFractionSaleTransaction(saleGuid, nftData, purchaseTxData, purchasePrice, purchasedFractions);
    },
    async getNftFeeData(productId: string): Promise<NftFeeManagerDataVM> {
      return this.altrApiService.getNftFeeData(productId);
    },
    async getLucidaoTokenPrice(): Promise<number> {
      const ENDPOINT = `${this.COINGECKO_BASE_URL}coins/lucidao/tickers`;
      const response = await fetch(ENDPOINT, {
        method: `GET`,
        headers: { Accept: `application/json` }
      });
      const token = await response.json();
      return token.tickers[0].converted_last.usd;
    },
    async getNftOrders(nftData: NFTDataVM, type: `ERC721` | `ERC1155`, makerAddress?: string) {
      if (type === `ERC721`) {
        return this.altrApiService.getErc721TraderOrder(nftData, makerAddress);
      } else {
        return this.altrApiService.getErc1155TraderOrder(nftData, makerAddress);
      }
    },
    async getMyTraderOrders() {
      return this.altrApiService.getMyTraderOrders();
    },
    async getOrdersByCollectionAddressAndTokenId(collectionAddress: string, tokenId: string, makerAddress?: string) {
      return this.altrApiService.getOrdersByCollectionAddressAndTokenId(collectionAddress, tokenId, makerAddress);
    },
    async sendEventSalePurchaseRequest(formValues: any) {
      return this.altrApiService.sendEventSalePurchaseRequest(formValues);
    },
    async sendEventSalePurchaseData(data: any) {
      return this.altrApiService.sendEventSalePurchaseData(data);
    },
    async getNfts(user: UserData, collectionsFilter?: string[]): Promise<OwnedNft[]> {
      if (!user?.address) {
        return [];
      }

      let nftCollections = (await this.altrApiService.getAltrCollections()).filter((n) => n);
      nftCollections = collectionsFilter?.length ? nftCollections.filter((collection: string) => collectionsFilter.includes(collection)) : nftCollections;

      let nfts = [] as OwnedNft[];

      const nftsIterable = this.alchemy.nft.getNftsForOwnerIterator(user.address, { contractAddresses: nftCollections });

      for await (const nft of nftsIterable) {
        if (nft?.image?.cachedUrl?.includes(`ipfs.io`)) {
          nft.image.cachedUrl = ipfsIoFallback(nft.image.cachedUrl);
        }

        nfts.push(nft);
      }

      return nfts;
    },
    async getNftMetadata(collectionAddress: string, nftId: string, nftType = NftTokenType.ERC721): Promise<Nft> {
      const metadata = await this.alchemy.nft.getNftMetadata(collectionAddress, nftId, nftType);

      return metadata;
    },
    async getLucidProductByNft(nft: OwnedNft) {
      return;
    },
    async requestBuyout(nftData: NFTDataVM) {
      return;
    },
    async subscribeUserToProductDrop(data: SubscribeToProductDropVM) {
      return this.altrApiService.subscribeUserToProductDrop(data);
    },
    async sendBuyAndClaimPurchaseRequest(formValues: any) {
      return this.altrApiService.sendBuyAndClaimPurchaseRequest(formValues);
    },
    async isExternallyOwnedAccount(address: string) {
      return !(await this.alchemy.core.isContractAddress(address));
    },
    async getLightCertificationRequests() {
      return this.altrApiService.getLightCertificationRequests();
    },
    async getLightCertificationRequestDetails(requestId: number) {
      return this.altrApiService.getLightCertificationRequestDetails(requestId);
    },
    async getLightCertificationRequestDetailsByGuid(guid: number) {
      return this.altrApiService.getLightCertificationRequestDetailsByGuid(guid);
    },
    async getFullCertificationRequests() {
      return this.altrApiService.getFullCertificationRequests();
    },
    async getFullCertificationRequestDetails(requestId: number) {
      return this.altrApiService.getFullCertificationRequestDetails(requestId);
    },
    async createFullCertificationRequest(requestIdentifier: string) {
      return this.altrApiService.createFullCertificationRequest(requestIdentifier);
    },
    async getRaffleReferralCode() {
      return this.altrApiService.getRaffleReferralCode();
    },
    async getNftHistory(): Promise<any> {
      throw new Error("Missing implementation");
    },
    async claimNft(nftData: NFTDataVM, email: string): Promise<any> {
      throw new Error("Missing implementation");
    },
    async getUserLiquidityProvisioning() {
      return this.altrApiService.getUserLiquidityProvisioning();
    },
    async getLiquidityProvisioningByPublicAddress(publicAddress: string) {
      return this.altrApiService.getLiquidityProvisioningByPublicAddress(publicAddress);
    },
    async getAllCustomerReferralCode() {
      return this.altrApiService.getAllCustomerReferralCode();
    },
    async getMyAddressHashed() {
      return this.altrApiService.getMyAddressHashed();
    },
    async getSellOrders(params: PaginatedQueryParams) {
      return this.altrApiService.getSellOrders(params);
    },
    async getSystemConfig() {
      return this.altrApiService.getSystemConfig();
    }
  };
  crypto = {
    async getOracleValuation(nftData: NFTDataVM): Promise<string> {
      const { nft } = await GetNftValuationHistory({ nftId: `${nftData.nftCollectionInfo.collectionAddress}${nftData.tokenId}` });
      return nft.valuationHistory.at(-1)?.price?.toString();
    },
    async refundFailedFractionsSale(nftData: NFTDataVM) {
      return null;
    },
    async canUserDoBuyout(nftData: NFTDataVM) {
      return true;
    },
    async getBuyoutData(nftData: NFTDataVM) {
      return null;
    },
    async approveNftForBuyout(nftData: NFTDataVM) {
      return null;
    },
    async approvePurchaseTokenForBuyout(nftData: NFTDataVM) {
      return null;
    },
    async performBuyout(nftData: NFTDataVM) {
      return null;
    },
    async retrieveWholeFromFragments(nftData: NFTDataVM) {
      return null;
    },
    async signEmailConfirmation() {
      return null;
    },
    approvePurchaseTokenForClaimFee(nftData: NFTDataVM) {
      return null;
    },
    getClaimFee(nftData: NFTDataVM) {
      return null;
    },
    payClaimFee(nftData: NFTDataVM) {
      return null;
    }
  };
}

const externalIntegrationsImpl = new ExternalIntegrationsImpl();

export default externalIntegrationsImpl;
